import React from 'react';
import logo from './logo.svg';
import bwLogo from './bw_logo.svg';
import amplifyconfig from './amplifyconfiguration.json';
import { configureAutoTrack, record } from 'aws-amplify/analytics';
import { Amplify } from 'aws-amplify';
import './App.css';
import { Typography, Layout, Flex, Modal, Menu, Dropdown, Space, MenuProps, Button, FloatButton, ConfigProvider } from 'antd';
const { useState, useEffect } = React;
const { Header, Content, Footer } = Layout;
const { Title, Text } = Typography;

const trackIncomingLinks = () => {
  // Assuming the URL is https://yourdomain.com/?source=campaignA
  const params = new URLSearchParams(window.location.search);
  const source = params.get('s'); // 'campaignA'
  if (source) {
    record({
      name: 'incomingLinkClick',
      attributes: { source },
    });
  }
};

Amplify.configure(amplifyconfig);

configureAutoTrack({
  // REQUIRED, turn on/off the auto tracking
  enable: true,
  // REQUIRED, the event type, it's one of 'event', 'pageView' or 'session'
  type: 'pageView',
});


function App() {
  useEffect(() => {
    trackIncomingLinks();
  }, []);

  const ProductDropdown = () => {
    const items: MenuProps['items'] = [
      {
        label: (
          <a target="_blank" rel="noopener noreferrer" href="https://www.briefwires.com/?s=ir">
            <img src={bwLogo} className="bw-logo" alt="bw-logo" />BriefWires
          </a>
        ),
        key: '0',
      },
    ];

    return (
      <Dropdown menu={{ items }} className='my-custom-dropdown'>
        <Text className="dropdown-trigger">
          Products
        </Text>
      </Dropdown>
    );
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <ConfigProvider
      theme={{
        components: {
          Menu: {
            itemHoverBg: "rgba(0, 0, 0, 0.88)",
            itemHoverColor: "transparent",
          },
        },
      }}
    >
      <Layout style={{ height: '100vh' }}>
        <Modal open={isModalOpen} footer={null} onOk={handleOk} onCancel={handleCancel}>
          <p>
            Ink River, LLC<br />
            contact@ink-river.com<br />
            651 North Broad Street, Suite 201<br />
            Middletown, DE, 19709</p>
        </Modal>
        <Header
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'white',
            justifyContent: 'space-between',
          }}
          className='My-header'
        >
          <Flex style={{ alignItems: 'center' }}>
            <img src={logo} className="App-logo" alt="logo" />
            <Title level={2} style={{ marginBottom: '30px', marginLeft: "-10px" }}>Ink River</Title>
          </Flex>
          {/* <ProductDropdown /> */}
        </Header>
        <Content
          style={{
            padding: '0 48px',
          }}
        >
          <Flex justify="center" align="center" vertical>
            <img src={logo} className="Content-logo" alt="logo" />
            <Title style={{ marginTop: "-10vmin", fontSize: "60px" }}>Ink River</Title>
            <Text style={{ marginTop: "-3vmin", fontSize: "20px" }}>Innovating technology to shape a better future</Text>
          </Flex>
        </Content>
        <Footer
          style={{
            textAlign: 'center',
            backgroundColor: 'white',
          }}
        >
          <div style={{ marginBottom: "1vmin" }}>©{new Date().getFullYear()} Ink River, LLC</div>
          <a onClick={showModal}><Text underline>Contact Us</Text></a>
        </Footer>
      </Layout >
    </ConfigProvider>

  );
}

export default App;
